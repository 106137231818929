<template>
  <!-- 编辑签名 -->
  <el-dialog
    :title="$t('label.emailobject.signature')"
    :visible.sync="isSign"
    width="45%"
    :append-to-body="true"
    custom-class="edict-sign"
  >
    <div v-loading="signloading">
      <wangEditor
        :emailInfo="emailInfo"
        :content="content"
        :inses="inse"
        @editorContent="editorContent"
      >
      </wangEditor>
      <div class="select-sign">
        <!-- 启用签名 vue_label_email_enable_signature-->
        <el-checkbox v-model="checked">{{
          $t("vue_label_email_enable_signature")
        }}</el-checkbox>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancleSelect">
        <!-- 取消 -->
        {{ $t("label.cancel") }}
      </el-button>
      <el-button type="primary" @click="saveSign" :loading="commitloading">
        <!-- 确认 -->
        {{ $t("label_chatter_ok") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import wangEditor from "../../components/emailSignEditor";
import * as Emailobject from "./api.js";
export default {
  name: "emailSign",
  data() {
    return {
      isSign: true,
      emailInfo: {},
      content: "", //
      inse: "",
      checked: false,
      commitloading: false,
      signloading: true,
      editorText: "",
      editicon: [
        "justifyleft",
        "justifycenter",
        "justifyright",
        "justifyfull",
        "insertorderedlist",
        "insertunorderedlist",
        "indent",
        "outdent",
        "subscript",
        "superscript",
        "clearhtml",
        "quickformat",
        "selectall",
        "fullscreen",
        "formatblock",
        "fontname",
        "fontsize",
        "forecolor",
        "hilitecolor",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "lineheight",
        "removeformat",
        "table",
        "hr",
        "pagebreak",
        "anchor",
        "link",
        "unlink",
      ],
    };
  },
  methods: {
    //富文本编辑器
    onContentChange(val) {
      this.editorText = val;
    },
    //保存签名
    async saveSign() {
      this.commitloading = true;
      let option = {
        content: this.editorText,
        isusing: this.checked,
      };
      let result = await Emailobject.saveEmailSign(option);
      if (result.result == true) {
        this.commitloading = false;
        if (this.checked == true) {
          this.$Bus.$emit("deliver-change-sign", this.editorText);
        } else {
          this.$Bus.$emit("deliver-email-sign", "");
        }
        //保存签名成功
        this.$message({
          showClose: true,
          message: this.$i18n.t(
            "vue_label_email_save_the_signature_successfully"
          ),
          type: "success",
        });
        this.isSign = false;
      }
    },
    //获取签名
    async getSign() {
      let result = await Emailobject.queryEmailSign({});
      if (result.result == true && result.data) {
        this.signloading = false;
        this.checked = result.data.isusing == "true" ? true : false;
        this.editorText = result.data.signContent;
        this.content = result.data.signContent;
      }
    },
    cancleSelect() {
      this.isSign = false;
      this.$emit("closedialogSign");
    },
    editorContent(val) {
      this.editorText = val;
    },
    deliverSignatureCallback(){
      this.isSign = true;
    }
  },
  watch: {
    isSign(val) {
      this.$Bus.$emit("change-sign-isSHow", val);
    },
  },
  mounted() {
    this.getSign();
    this.$Bus.$on("deliver-signature", this.deliverSignatureCallback);
  },
  beforeDestroy(){
    this.$Bus.$off("deliver-signature", this.deliverSignatureCallback);
  },
  components: {
    wangEditor,
  },
};
</script>

<style lang='scss' scoped>
@import "@/style/dialogStyle.scss";
::v-deep .el-dialog__body {
  padding: 16px 36px !important;
}
::v-deep .el-dialog__wrapper {
  z-index: 2500;
}
.select-sign {
  margin-top: 10px;
}
::v-deep .el-dialog.edict-sign .el-dialog__body {
  padding: 16px 36px !important;
}
</style>