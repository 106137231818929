<template>
  <div>
    <el-dialog
      :title="this.$i18n.t('vue_label_email_insertmergefield')"
      :visible.sync="istabimg"
      :append-to-body="true"
      :before-close="closedailog"
      width="60%"
    >
      <div class="tips">
        <!-- 请选择一个字段，然后点击“插入，后缀“>”的标签表示还有更多可用字段 -->
        {{ $t("label.emailobject.template.message1") }}
      </div>
      <div v-if="dataList.length > 0">
        <div
          class="list-body"
          v-for="(itemList, index) in dataList"
          :key="index"
        >
          <ul>
            <li
              v-for="(item, indexl) in itemList"
              :key="indexl"
              @click="getNewList(item, index, indexl)"
              :style="{
                background:
                  (selectIndex[0].position == index &&
                    selectIndex[0].index == indexl) ||
                  (selectIndex[1].position == index &&
                    selectIndex[1].index == indexl) ||
                  (selectIndex[2].position == index &&
                    selectIndex[2].index == indexl)
                    ? '#D8D8D8'
                    : '',
              }"
            >
              {{ item.labelname }}
              <span
                v-show="
                  (!item.fieldtype ? true : item.lookupObj ? true : false) &&
                  index < 2
                "
                >></span
              >
            </li>
          </ul>
        </div>
        <div class="is-select-peo">{{ selectFields }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closedailog">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button type="primary" @click="insertFieldsMethod">
          <!-- 插入 -->
          {{ $t("label.insert") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as Emailobject from "./api.js";
export default {
  name: "addEmailFields",
  props: {
    istabimg: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      objectname: "",
      selectIndex: [
        { position: 0, index: null, fields: "" },
        { position: 1, index: null, fields: "" },
        { position: 2, index: null, fields: "" },
      ],
      recordId: "",
      dataList: [],
      //选中的字段
      selectFields: "",
      selectobj: "",
    };
  },
  mounted() {
    this.getObjectList();
  },
  methods: {
    //关闭弹框
    closedailog() {
      this.$emit("closedailogFields", false);
    },
    //插入字段
    insertFieldsMethod() {
      this.$emit("insertFieldsMethod");
    },
    //获取对象列表
    async getObjectList() {
      let result = await Emailobject.getobjectlist();
      if (result.result == true && result.data) {
        this.dataList.push(result.data);
      }
    },
    //获取字段列表
    async getNewList(item, index, indexl) {
      if (index == 0) {
        this.selectIndex[0].index = indexl;
        this.selectIndex[0].fields = item.id;
        this.recordId = item.id;
      } else if (index == 1) {
        this.selectIndex[1].index = indexl;
        this.selectIndex[1].fields = item.fieldname;
      } else if (index == 2) {
        this.selectIndex[2].index = indexl;
        this.selectIndex[2].fields = item.fieldname;
      }
      if (index == 1) {
        this.dataList.splice(index + 1);
        this.selectIndex[index + 1].index = null;
        this.selectIndex[index + 1].fields = null;
      }
      if (index == 0) {
        this.dataList.splice(index + 1);
        this.selectIndex[index + 1].index = null;
        this.selectIndex[index + 1].fields = null;
        this.selectIndex[index + 2].index = null;
        this.selectIndex[index + 2].fields = null;
      }
      // 已选中
      this.selectFields = `${this.$i18n.t("label.selected")}"${
        item.labelname
      }"`;
      let option = {
        objid: item.lookupObj ? item.lookupObj : item.id,
        num: index + 1,
      };
      let optionFields = "";
      this.selectIndex.forEach((res, index) => {
        if (res.fields != null && index == 0) {
          optionFields += `!${this.objectname}`;
        } else if (res.fields != null && index != 0) {
          optionFields += `.${res.fields}`;
        }
      });
      this.$Bus.$emit("deliver-insert-fields", optionFields);

      if ((!item.lookupObj && index != 0) || index >= 2) {
        return false;
      }
      let result = await Emailobject.getsize(option);
      if (
        result.result == true &&
        result.data &&
        result.data.fields.length != 0
      ) {
        if (result.data.objectmap) {
          this.objectname = result.data.objectmap.objectname;
        }

        this.dataList.push(result.data.fields);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  margin-bottom: 10px;
}
.is-select-peo {
  display: inline-block;
  transform: translateY(-150px);
  overflow: hidden;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list-body {
  display: inline-block;
  width: 150px;
  height: 300px;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  margin-right: 10px;
  overflow: auto;
  ul {
    li {
      padding: 0 10px;
      line-height: 30px;
      overflow: hidden;
      height: 30px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #080707;
      cursor: pointer;
    }
  }
}
</style>
