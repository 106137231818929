var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":this.$i18n.t('vue_label_email_insertmergefield'),"visible":_vm.istabimg,"append-to-body":true,"before-close":_vm.closedailog,"width":"60%"},on:{"update:visible":function($event){_vm.istabimg=$event}}},[_c('div',{staticClass:"tips"},[_vm._v(" "+_vm._s(_vm.$t("label.emailobject.template.message1"))+" ")]),(_vm.dataList.length > 0)?_c('div',[_vm._l((_vm.dataList),function(itemList,index){return _c('div',{key:index,staticClass:"list-body"},[_c('ul',_vm._l((itemList),function(item,indexl){return _c('li',{key:indexl,style:({
              background:
                (_vm.selectIndex[0].position == index &&
                  _vm.selectIndex[0].index == indexl) ||
                (_vm.selectIndex[1].position == index &&
                  _vm.selectIndex[1].index == indexl) ||
                (_vm.selectIndex[2].position == index &&
                  _vm.selectIndex[2].index == indexl)
                  ? '#D8D8D8'
                  : '',
            }),on:{"click":function($event){return _vm.getNewList(item, index, indexl)}}},[_vm._v(" "+_vm._s(item.labelname)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                (!item.fieldtype ? true : item.lookupObj ? true : false) &&
                index < 2
              ),expression:"\n                (!item.fieldtype ? true : item.lookupObj ? true : false) &&\n                index < 2\n              "}]},[_vm._v(">")])])}),0)])}),_c('div',{staticClass:"is-select-peo"},[_vm._v(_vm._s(_vm.selectFields))])],2):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closedailog}},[_vm._v(" "+_vm._s(_vm.$t("label.cancel"))+" ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.insertFieldsMethod}},[_vm._v(" "+_vm._s(_vm.$t("label.insert"))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }